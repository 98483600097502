import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import "../Styles/AppointmentForm.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../Components/Footer";
import image1 from '../Assets/image1.jpg';
import image2 from '../Assets/image2.jpg';
import image3 from '../Assets/image3.jpg';
import image4 from '../Assets/image4.jpg';

function AppointmentForm() {
  const settings = {
    dots: true,            // Show navigation dots below the slider
    infinite: true,        // Infinite loop
    speed: 500,            // Transition speed in milliseconds
    slidesToShow: 1,       // Number of slides to show at once
    slidesToScroll: 1,     // Number of slides to scroll when navigating
    autoplay: true,        // Enable auto-slide
    autoplaySpeed: 3000,   // Delay between each auto-slide (in milliseconds)
  };

  const images = [
    image1, image2, image3, image4
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  const [patientName, setPatientName] = useState("");
  const [patientNumber, setPatientNumber] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientQuery, setPatientQuery] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form inputs
    const errors = {};
    if (!patientName.trim()) {
      errors.patientName = "Patient name is required";
    } else if (patientName.trim().length < 8) {
      errors.patientName = "Patient name must be at least 8 characters";
    }

    if (!patientNumber.trim()) {
      errors.patientNumber = "Patient phone number is required";
    } else if (patientNumber.trim().length !== 10) {
      errors.patientNumber = "Patient phone number must be of 10 digits";
    }

    if (!patientQuery.trim()) {
      errors.patientQuery = "Patient Query is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // EmailJS integration
    emailjs.send(
      "service_g6jz722",
      "template_ypbv71k",
      {
        patient_name: patientName,
        patient_number: patientNumber,
        patient_email: patientEmail,
        patient_query: patientQuery,
      },
      "w_1xiBnUtiTod4A3b"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        toast.success("Message Sent!", {
          position: toast.POSITION.TOP_CENTER,
          onOpen: () => setIsSubmitted(true),
          onClose: () => setIsSubmitted(false),
        });

        // Reset form fields and errors after successful submission
        setPatientName("");
        setPatientNumber("");
        setPatientEmail("");
        setPatientQuery("");
        setFormErrors({});
      })
      .catch((error) => {
        console.log("FAILED...", error);
        toast.error("Failed to send message. Please try again later.");
      });
  };

  return (
    <div className="appointment-form-section">
      <h1 className="legal-siteTitle">
        <Link to="/">Niazi ENT <span className="legal-siteSign">Clinic</span></Link>
      </h1>

      <div className="main-content">
        <div className="form-container">
          <h2 className="form-title">
            <span>Contact Us</span>
          </h2>

          <form className="form-content" onSubmit={handleSubmit}>
            <label>
              Patient Full Name:
              <input
                type="text"
                value={patientName}
                onChange={(e) => setPatientName(e.target.value)}
                required
              />
              {formErrors.patientName && <p className="error-message">{formErrors.patientName}</p>}
            </label>

            <label>
              Patient Phone Number:
              <input
                type="text"
                value={patientNumber}
                onChange={(e) => setPatientNumber(e.target.value)}
                required
              />
              {formErrors.patientNumber && <p className="error-message">{formErrors.patientNumber}</p>}
            </label>

            <label>
              Patient Email:
              <input
                type="text"
                value={patientEmail}
                onChange={(e) => setPatientEmail(e.target.value)}
              />
              {formErrors.patientEmail && <p className="error-message">{formErrors.patientEmail}</p>}
            </label>

            <label>
              Query:
              <input
                type="text"
                value={patientQuery}
                onChange={(e) => setPatientQuery(e.target.value)}
                required
              />
              {formErrors.patientQuery && <p className="error-message">{formErrors.patientQuery}</p>}
            </label>

            <button type="submit" className="text-appointment-btn">
              Send Message
            </button>

            <p className="success-message" style={{ display: isSubmitted ? "block" : "none" }}></p>
          </form>
        </div>

        <div className="slider-container">
          <Slider {...settings}>
            {images.map((src, index) => (
              <div key={index}>
                <img src={src} alt={`Slide ${index + 1}`} className="slider-image" />
              </div>
            ))}
          </Slider>
        </div>

      </div>

      <Footer />
      {/* <div className="legal-footer">
        <p>© 2024 Niazi ENT Clinic. All rights reserved. Powered by CodeShield Technologies</p>
      </div> */}

      <ToastContainer autoClose={5000} limit={1} closeButton={false} />
    </div>
  );
}

export default AppointmentForm;
