export const customerReviews = [
    {
      "name": "Muhammad Abbas",
      "location": "Multan, Pakistan",
      "message": "Great experience! Friendly staff, knowledgeable doctor, and quick results. Highly recommend"
    },
    {
      "name": "Hanzala Majeed",
      "location": "Rawalpindi, Pakistan",
      "message": "Amazing service! The doctor resolved my sinus issues in no time. Very satisfied."
    },
    {
      "name": "Maryam tariq",
      "location": "Rawalpindi, Pakistan",
      "message": "Fantastic care! The team was professional and attentive. My ear infection cleared up fast."
    },
    {
      "name": "Atif Arsalan",
      "location": "Islamabad, Pakistan",
      "message": "Efficient and effective! The doctor quickly diagnosed my problem and provided excellent treatment."
    },
    {
      "name": "Bilal Obaid",
      "location": "Okara, Pakistan",
      "message": "Top-notch clinic! Friendly staff and a doctor who really listens. I’m very happy with the care I received."
    },
    {
      "name": "Uzair Laif",
      "location": "Peshawar, Pakistan",
      "message": "Outstanding service! My throat issue was diagnosed and treated perfectly. Very impressed."
    }
];
