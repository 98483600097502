import React from "react";
import Doctor from "../Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          With a steadfast commitment to your well-being, 
          our team of highly trained healthcare professionals ensures that you receive nothing 
          short of exceptional patient experiences.
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="OPD Consultation"
        />

        <SolutionStep
          title="Hearing evaluation and Fitting of hearing Aids."
        />

        <SolutionStep
          title="Pharmacy"
        />

        <SolutionStep
          title="Tinitis management by intratympanic PRP"
        />

        <SolutionStep
          title="Laboratory Tests"
        />
      </div>

      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>
    </div>
  );
}

export default About;
