import React from "react";
import "../Styles/Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              Niazi ENT <span className="ft-sign">Clinic</span>
            </p>
            <p className="ft-description">
              We are honored to be a part of your healthcare journey and committed to 
              delivering compassionate, personalized, and top-notch care every step of 
              the way.
            </p>
          </div>

          <SubscribeNewsletter />
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Laboratory Test</a>
            </li>
            <li>
              <a href="#services">Health Check</a>
            </li>
            <li>
              <a href="#services">General Dentistry</a>
            </li>
            <li>
              <a href="#services">Prescription</a>
            </li>
            <li>
              <a href="#services">Insights for doctors</a>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="https://maps.app.goo.gl/csBEoGLiUMkXCzB98">1st Floor, Rafah Mall, Near Chering Cross, <br></br>Peshawar Road, Rawalpindi</a>
            </li>
            <li>
              <a href="https://maps.app.goo.gl/JjzQ1VpgURf9H8V46">Clinic No. , Mega Hospital, Shaheen Plus, Opposite AFIC, <br></br>Mall Road, Rawalpindi</a>
            </li>
            <li>
              <a href="mailto:saleem.niazi91@gmail.com">
                saleem.niazi91@gmail.com
              </a>
            </li>
            <li>
              <a href="tel:+92-305-4999421">+92-305-4999421</a>
            </li>
            <li>
              <a href="tel:+92-314-1930268">+92-314-1930268</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 Niazi ENT Clinic. All rights reserved. Powered by CodeShield Technologies</p>
      </div>
    </div>
  );
}

export default Footer;
