import React from "react";
import DoctorCard from "./DoctorCard";
import profile1 from "../Assets/profile-1.png";
import "../Styles/Doctors.css";

function Doctors() {
  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Meet Our Doctor</span>
        </h3>

        <p className="dt-description">
        Dr. Saleem Asif Niazi is a highly qualified and experienced ENT specialist with a 
        distinguished career in the field of ear, nose, and throat disorders. 
        With a comprehensive medical background that includes an MBBS degree, a Membership of the 
        College of Physicians and Surgeons (MCPS), and a Fellowship of the College of Physicians and Surgeons (FCPS),
        Dr. Saleem Asif Niazi is well-equipped to provide expert care. 
        Additionally, Dr. Saleem Asif Niazi holds a specialized diploma in ENT from the Armed Forces 
        Postgraduate Medical Institute (AFPGMI), further solidifying their expertise.
        </p>
      </div>

      <div className="dt-cards-content">
        <DoctorCard
          img={profile1}
          name="Brig (R) Saleem Asif Niazi"
          title="Prof & Consultant ENT"
          stars="5"
          reviews="1800"
        />
      </div>
    </div>
  );
}

export default Doctors;
