import React from "react";
import InformationCard from "./InformationCard";
import { faHeartPulse, faTruckMedical, faTooth } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
          We bring the best ENT care to your convenience, offering a comprehensive
          range of on-demand medical services tailored to your needs.
        </p>
      </div>

      <div className="info-cards-content">
      <InformationCard
          title="Health Check"
          description="Comprehensive ENT care. The only ENT Clinic in the locality. Providing ENT examination 
          evaluattion. Diagnosis and Management including ENT surgeries and Post operative care. The
          clinic is equiped to take care of Ringing in ears (Tinitis)."
          icon={faHeartPulse}
        />

        <InformationCard
          title="Laboratory Test"
          description="Experience precision with our laboratory testing services. 
          We offer accurate diagnostics and swift results, ensuring you get the answers you need quickly. 
          Our state-of-the-art equipment and skilled professionals deliver reliable results you can trust. "
          icon={faTruckMedical}
        />

        <InformationCard
          title="ENT Surgeries"
          description="The clinic specializes in different ENT surgeries like tonsilectomies, septal surgeries, Rhinoplasties
          (Nose Job). Reconstruction of perforated ear drum endoscopally. The clinic is equiped to perform simple head and neck surgeries."
          icon={faTooth}
        />
      </div>
    </div>
  );
}

export default Info;
